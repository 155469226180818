body {
  position: fixed;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
    width: 100vw;
    min-height: 1280px;
    height: 100vh;
    min-height: 960px;
    background: #EEEEEE;
}

::-webkit-scrollbar{
  width: 16px;
  height: 16px;
}
::-webkit-scrollbar-track{
  background: #eee;
  border: 5px solid #fff;
  background-clip: content-box;
}
::-webkit-scrollbar-thumb{
  border: 3px solid #fff;
  background: #38496D;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
